<script>
  function scrollToSection2() {
    const section2 = document.getElementById("section2");
    section2?.scrollIntoView({ behavior: "smooth" });
  }
</script>
<nav class="z-10 bg-white mx-4 lg:mx-12 2xl:mx-32 pt-5">
  <div class="container mt-3">
    <div class="max-w-[128px]">
      <a on:click={() => scrollToSection2()} href="/" >
        <img src="/logo-original.webp" alt="logo" height="44" width="128px" loading="eager"/>
      </a>
    </div>
  </div>
</nav>
