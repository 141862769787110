<script>
    import { Splide, SplideSlide } from '@splidejs/svelte-splide';
    import { Swiper, SwiperSlide } from "swiper/svelte";
    import { Pagination } from "swiper";
    import '@splidejs/svelte-splide/css/core';
</script>

<div class="bg-[#FFEFEE] w-full mt-16 lg:mt-4 py-8" style="border-radius: 16px;">
  <div class="px-12">
    <h1 class="font-[600] text-[20px] md:text-[24px] lg:text-[30px] mb-10 text-primary text-center">
      Studi Kasus
    </h1>
    <div class="mb-0 mx-auto">
      <Swiper
        freeMode={true}
        grabCursor={true}
        pagination={true}
        modules={[Pagination]}
        breakpoints={{
          "240": {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          "480": {
            slidesPerView: 2,
            spaceBetween: 0,
          },
          "640": {
            slidesPerView: 3,
            spaceBetween: 12,
          },
          "768": {
            slidesPerView: 5,
            spaceBetween: 12,
          }
        }}
        class="mySwiper">
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://katetigama.id/" target="_blank">
              <img
                class="h-[50px]"
                loading="eager"
                src="katetigama.png"
                alt="casestudy- 4"
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://perpesi.org" target="_blank">
              <picture>
                <img
                  sizes="(max-width: 700px) 100vw, 700px"
                  class="h-[50px]"
                  loading="eager"
                  alt="casestudy- 2"
                  srcset="
                    perpesi_nri0dz_c_scale,w_200.webp 200w,
                    perpesi_nri0dz_c_scale,w_700.webp 700w"
                  src="perpesi_nri0dz_c_scale,w_700.webp"
                />
              </picture>
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://ikasmansapku.munio.id" target="_blank">
              <img
                class="h-[70px]"
                loading="eager"
                src="ikasmansa-alt.png"
                alt="casestudy-5"
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://vhiweb.com/project/unesco" target="_blank">
              <img
                class="h-[50px]"
                loading="eager"
                src="unesco.png"
                alt="casestudy- 3"
              />
            </a>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div class="flex flex-row justify-center items-center">
            <a href="https://vhiweb.com/project/jokowi-app" target="_blank">
              <picture>
                <img
                  sizes="(max-width: 653px) 100vw, 653px"
                  srcset="
                    jokowiapp_qb61of_c_scale,w_200.webp 200w,
                    jokowiapp_qb61of_c_scale,w_653.webp 653w"
                  src="jokowiapp_qb61of_c_scale,w_653.webp"
                  class="h-[50px]"
                  loading="eager"
                  alt="casestudy- 1"
                />
              </picture>
            </a>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
    <!-- <div class="flex flex-col w-full mt-4 lg:mt-0">
      <div class="gap-12 md:gap-4 xl:gap-12 flex flex-wrap md:flex-row justify-center lg:justify-end xl:justify-end items-center px-0 w-full my-auto">
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/unesco" target="_blank">
            <img
              class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
              loading="eager"
              width="120px"
              height="50px"
              src="unesco.png"
              alt="casestudy- 3"
            />
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/perpesi" target="_blank">
            <picture>
              <img
                sizes="(max-width: 700px) 100vw, 700px"
                class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
                loading="eager"
                width="120px"
                height="50px"
                alt="casestudy- 2"
                srcset="
                  perpesi_nri0dz_c_scale,w_200.webp 200w,
                  perpesi_nri0dz_c_scale,w_700.webp 700w"
                src="perpesi_nri0dz_c_scale,w_700.webp"
              />
            </picture>
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://katetigama.munio.id/" target="_blank">
            <img
              class="max-w-[100px] h-auto mx-auto md:mr-[1rem]"
              loading="eager"
              width="120px"
              height="50px"
              src="katetigama.png"
              alt="casestudy- 4"
            />
          </a>
        </Lazy>
        <Lazy height={300}>
          <a href="https://vhiweb.com/project/jokowi-app" target="_blank">
            <picture>
              <img
                sizes="(max-width: 653px) 100vw, 653px"
                srcset="
                  jokowiapp_qb61of_c_scale,w_200.webp 200w,
                  jokowiapp_qb61of_c_scale,w_653.webp 653w"
                src="jokowiapp_qb61of_c_scale,w_653.webp"
                class="max-w-[100px] h-auto mx-auto"
                loading="eager"
                width="120px"
                height="50px"
                alt="casestudy- 1"
              />
            </picture>
          </a>
        </Lazy>
      </div>
    </div> -->
  </div>
</div>