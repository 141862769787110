<script>
  import Footer from "$lib/components/navigation/Footer.svelte";
  import Navbar from "$lib/components/navigation/Navbar.svelte";
  import CaseStudy from "$lib/components/section/case-study.svelte";
  import Features from "$lib/components/section/features.svelte";
  import Hero from "$lib/components/section/hero.svelte";
  import MunioSection from "$lib/components/section/munio-section.svelte";
  import OurServices from "$lib/components/section/our-services.svelte";
  import TellUsSection from "$lib/components/section/tellus.svelte";
</script>

<Navbar />
<Hero />
<div class="mx-4 lg:mx-12 2xl:mx-32">
  <CaseStudy />
  <OurServices />
  <Features />
</div>
<MunioSection />
<TellUsSection />
<Footer />
