<script>
    function sendEmail() {
        window.location.href =
        "mailto:andy@vhiweb.com?subject=Permintaan Get Trial Munio";
    }
    function goTopSection() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
</script>

<div class="hero min-h-screen bg-[#FF5C54]"
    style="background-image: url('/Bg-munio-section.webp'); background-size: fit; opacity: 100%; background-repeat: no-repeat; background-blend-mode: color-dodge">
    <div class="hero-content text-center text-neutral-content">
        <div class="max-w-[900px] flex flex-col gap-4">
            <p class="text-center text-white text-base font-semibold font-['Inter'] capitalize leading-snug">Let's Collaborate Together</p>
            <h1 class="mb-5 text-4xl font-bold text-primary">Buat komunitas Anda naik kelas.</h1> 
            <button class="btn btn-primary gap-2 w-52 mx-auto hover:text-white text-primary"
            on:click={goTopSection}>Mulai Sekarang
            </button>
        </div>
    </div>
</div>

<style>
    .min-h-screen {
        min-height: 60vh;
    }
    h1 {
        color: #FDFDFD;
    }
    button {
        background-color: #FFEFEE;
    }
</style>