<script lang="ts">
  import { Swiper, SwiperSlide } from "swiper/svelte";
  import { Pagination } from "swiper";
  // Import Swiper styles
  import "swiper/css";
  import "swiper/css/effect-fade";
  import "swiper/css/navigation";
  import "swiper/css/pagination";
  import "swiper/css/scrollbar";
</script>

<div
  class="h-48 flex flex-col container mx-auto justify-center md:items-center md:mt-[100px] mb-[2px] mt-20 lg:mt-28" id="section2">
  <h1
    class="text-[20px] md:text-[24px] lg:text-[30px] font-[600] text-primary text-center md:text-center mb-[1rem] md:mb-4">
    Our Services
  </h1>
  <h1
    class="text-center md:text-center text-black leading-normal font-[600] text-[24px] md:text-[30px] lg:text-[40px]">
    Integrated Digital Platform
  </h1>
</div>
<div class="mb-5 mx-auto">
  <Swiper
    grabCursor={true}
    freeMode={true}
    pagination={true}
    modules={[Pagination]}
    breakpoints={{
      "240": {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      "480": {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      "640": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "768": {
        slidesPerView: 2,
        spaceBetween: 12,
      },
      "1024": {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      "1324": {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    }}
    class="mySwiper gap-4 2xl:gap-[24px]">
    <SwiperSlide>
      <div
        class="card w-auto h-[13.5rem] md:h-52 bg-base-100 card-bordered border-[#D8D8D866] shadow-sm">
        <div class="card-body">
          <div class="flex items-center">
            <img
              src="/card-1.webp"
              alt="Gambar-1"
              class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
              height="48px"
              width="48px"
              loading="eager"
            />
            <p class="text-md md:text-lg font-bold text-[#101828]">
              Membership <br /> 
            </p>
          </div>
          <p class="text-[#70747E] font-[400] text-[16px] mt-3">
            Pengelompokan data anggota secara terpusat dan fleksibel.
          </p>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div
        class="card w-auto h-[13.5rem] md:h-52 bg-base-100 card-bordered border-[#D8D8D866] shadow-sm">
        <div class="card-body">
          <div class="flex items-center">
            <img
              src="/card-3.webp"
              alt="Gambar-3"
              class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
              height="48px"
              width="48px"
              loading="eager"
            />
            <p class="text-md md:text-lg font-bold text-[#101828]">
              Content <br>
              Management
            </p>
          </div>
          <p class="text-[#70747E] font-[400] text-[16px] mt-3">
            Publikasikan berita, acara, atau gerakan untuk kalangan tertentu.
          </p>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div
        class="card w-auto h-[13.5rem] md:h-52 bg-base-100 card-bordered border-[#D8D8D866] shadow-sm">
        <div class="card-body">
          <div class="flex items-center">
            <img
              src="/card-2.webp"
              alt="Gambar-2"
              class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
              height="48px"
              width="48px"
              loading="eager"
            />
            <p class="text-md md:text-lg font-bold text-[#101828]">
              Broadcast
            </p>
          </div>
          <!-- text-[12px] -->
          <p class="text-[#70747E] font-[400] text-[16px]  mt-3">
            Kirim informasi ke anggota terpilih dengan kanal secara instan.
          </p>
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div
        class="card w-auto h-[13.5rem] md:h-52 bg-base-100 card-bordered border-[#D8D8D866] shadow-sm">
        <div class="card-body">
          <div class="flex items-center">
            <img
              src="/card-2.webp"
              alt="Gambar-2"
              class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
              height="48px"
              width="48px"
              loading="eager"
            />
            <p class="text-md md:text-lg font-bold text-[#101828]">
              Online Payment
            </p>
          </div>
          <!-- text-[12px] -->
          <p class="text-[#70747E] font-[400] text-[16px] mt-3">
            Terima pembayaran anggota, acara, atau galang dana secara aman dan nyaman.
          </p>
        </div>
      </div>
    </SwiperSlide>
    
    <!-- <SwiperSlide> -->
      <!-- <div
        class="card w-72 md:w-96 bg-base-100 card-bordered border-[#D8D8D866] shadow-sm">
        <div class="card-body">
          <div class="flex items-center">
            <img
              src="/card-4.webp"
              alt="Gambar-1"
              class="max-w-[48px] max-h-[48px] object-cover rounded-md mr-4"
              height="48px"
              width="48px"
              loading="eager"
            />
            <p class="text-md md:text-lg font-bold text-[#101828]">
              Online <br /> Payment
            </p>
          </div>
          <p class="text-[#646464] md:text-[16px] text-[12px] mt-6">
            Receive payment for member <br />
            subscriptions, events, or crowdfundings.
          </p>
        </div>
      </div> -->
    <!-- </SwiperSlide> -->
  </Swiper>
</div>
